.select-token-dropdown .dropdown-menu {
    background: var(--color-third);
}

.scrollable-dropdown-menu {
    max-height: 300px;
    max-width: 300px;
    min-width: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.select-token-dropdown .scrollable-dropdown-menu::-webkit-scrollbar {
    width: .7em;
}

.select-token-dropdown .scrollable-dropdown-menu::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
    height: 25px;
    /* outline: 1px solid slategrey; */
}


.select-token-dropdown .dropdown-menu .dropdown-item {
    color: white;
}

.select-token-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: var(--color-third);
}

.select-token-dropdown .select-token-dropdown-filter {
    margin-bottom: .2rem;
}

.select-token-dropdown-filter-icon {
    color: #aaaaaa;
    background: white;
    width: 20px;
    height: auto;
}

.select-token-dropdown .select-token-dropdown-filter-input {
    width: 100%;
    border: none !important;
    box-shadow: none !important;
}

.select-token-dropdown .select-token-dropdown-filter-input:focus {
    outline: none !important;
}

.token-price-change svg {
    font-size: 1rem;
}