#big-board {
    position: relative;
    width: 100%;
    color: white;
    /* height: 100vh;
    max-height: 100vh; */
    /* padding: 60px 0; */
    transform: scale(1);
    font-family: "Satoshi";

    --primary-linear-gradient: linear-gradient(to right, #E8D0A6 0%, #FFF1DB 55%, #E8D0A6 100%);
}

.modal.show {
    background: rgba(20, 20, 20, 0.6);
}

.btn-default,
.btn-default:disabled {
    position: relative;
    color: white;
    background: var(--color-second);
    /* background: linear-gradient(to right, var(--color-second) 0%, var(--color-second) 100%); */
    border: 1px solid var(--color-third);
    overflow: hidden;
    transition: all .3s;
}

.btn-default:active,
.btn-default:hover {
    color: black !important;
    background: rgba(0, 0, 0, 0);
}


.btn-default.selected {
    color: black !important;
    background: var(--primary-linear-gradient) !important;
}


.btn-default::after {
    position: absolute;
    content: "";
    inset: 0;
    background-image: var(--primary-linear-gradient);
    z-index: -1;
    opacity: 0;
    transition: opacity .3s linear;
}

.btn-default:hover::after {
    color: black;
    opacity: 1;
}
.btn-default:disabled:hover {
    opacity: 0.75;
}

.long-bg {
    background: var(--selection-color) !important;
}

.short-bg {
    background: var(--error-color) !important;
}

.currency-icon img {
    width: auto;
    height: 15px;
    margin-bottom: 3px;
}

.header-gradient {
    background: #FFFFFF;
    background: linear-gradient(to right, #ABABAB 0%, #FFFFFF 35%, #ABABAB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}


.react-datepicker-popper {
    z-index: 1000 !important;
}

.react-datepicker-popper .react-datepicker .react-datepicker__header,
.react-datepicker-popper .react-datepicker .react-datepicker__month,
.react-datepicker-popper .react-datepicker .react-datepicker__month-container,
.react-datepicker__input-time-container {
    background: var(--color-first) !important;
    color: white !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: inherit !important;
}

.react-datepicker__day:not(.react-datepicker__day--disabled):hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    color: black !important;
}

.react-datepicker__day--disabled,
.react-datepicker__day--disabled:hover {
    background: var(--color-third) !important;
    border-radius: 0.3rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    color: black !important;
    background-color: white !important;
}

.react-datepicker__navigation-icon--next::before,
.react-datepicker__navigation-icon--previous::before {
    border-color: white !important;
}

.react-datepicker__input-time-container {
    margin: 0 !important;
    padding: 5px 0 10px 15px;
}


#big-board-news-feed-container {
    position: sticky;
    width: 30%;
    top: 75px;
    height: calc(100vh - 75px);
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 6%;
    padding-right: 4%;
}

#big-board-alpha-posts-container {
    width: 40%;
}

#big-board-filters-and-leaderboard-container {
    position: sticky;
    width: 30%;
    top: 75px;
    height: calc(100vh - 75px);
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 6%;
    padding-left: 4%;
}