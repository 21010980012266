#filters-widget-tokens-search-icon {
    color: #aaaaaa;
    background: white;
    width: 20px;
    height: auto;
}

#filters-widget-tokens-search-bar-input {
    width: 100%;
    border: none !important;
    box-shadow: none !important;
}

#filters-widget-tokens-search-bar-input:focus {
    outline: none !important;
}


#filters-widget-tokens .scrollable-container {
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
}

#filters-widget-tokens .scrollable-container::-webkit-scrollbar {
    border-radius: 2.3rem;
    width: 5px;
}

#filters-widget-tokens .scrollable-container::-webkit-scrollbar-thumb {
    background: var(--color-fourth);
    width: 5px;
    border-radius: 2.3rem;
}

#filters-widget-tokens .scrollable-container::-webkit-scrollbar-track {
    background: var(--color-third);
    border-radius: 2.3rem;
    margin: 5px 0;
}


.token-item {
    display: flex;
    align-items: center;
}

.token-item:hover {
    background-color: var(--color-third);
    cursor: pointer;
}

.selected-item {
    background: var(--color-third);
    color: white;
    /* border-radius: .4rem; */
}