#telegram-connection-loader {
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: var(--color-first);

    padding-top: 13rem;
    z-index: 100;
}

#telegram-connection-loader .loading-icon {
    filter: invert(1);
}