#leaderboard-widget {
    position: relative;
    width: 100%;
    height: 320px;
    background: var(--color-second);
    border-radius: 2.3rem;
    border: 1px solid var(--color-third);
    overflow: hidden;
}

#leaderboard-widget-content {
    background: inherit;
    overflow-y: scroll;
}

#leaderboard-widget-content::-webkit-scrollbar {
    border-radius: 2.3rem;
    width: 5px;
}

#leaderboard-widget-content::-webkit-scrollbar-thumb {
    background: var(--color-fourth);
    width: 5px;
    border-radius: 2.3rem;
}

#leaderboard-widget-content::-webkit-scrollbar-track {
    background: var(--color-third);
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 2.3rem;
}

#leaderboard-widget-header {
    position: sticky;
    top: 0;
    background: inherit;
    text-align: center;
    border-bottom: 1px solid var(--color-third);
}

.leaderboard-widget-body-entry {
    width: 100%;
    padding-left: 10%;
    /* border-top: 1px solid var(--color-third); */
    border-bottom: 1px solid var(--color-third);
}

.leaderboard-widget-body-entry-user img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}