.alpha-post-preview {
    position: relative;
    width: 100%;
    /* max-height: 350px; */
    overflow: hidden;
    border-radius: 1.5rem;
    border: 1px solid var(--color-third);
    background: var(--color-second);
    transition: all .5s;
}

.alpha-post-preview:hover {
    background: #222222;
}

.alpha-post-preview .information-container:hover {
    cursor: pointer;
}

.alpha-post-preview-shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.5); */
    pointer-events: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.alpha-post-preview .interactions-container {
    border-right: 1px solid var(--color-third);
}


.post-trade-details .token-info .price-at-creation {
    color: grey;
}

.post-trade-details .price-range-info.uptrend {
    color: var(--selection-color);
}

.post-trade-details .price-range-info.downtrend {
    color: var(--error-color);
}

.alpha-post-research-preview {
    max-height: 150px;
}

.alpha-post-research-chart,
.alpha-post-research-chart img {
    width: 100%;
    height: auto;
}

.alpha-post-creation {
    position: sticky;
    bottom: 8px;
    font-size: .8rem;
    color: var(--color-primary);

    z-index: 10;
}

.alpha-post-bottom-fade {
    position: absolute;
    width: 100%;
    height: 200px;
    background: linear-gradient(0deg, var(--color-second) 0%, rgba(255,255,255,0) 100%);
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
}