#news-feed-widget {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--color-second);
    border-radius: 2.3rem;
    border: 1px solid var(--color-third);
    overflow: hidden;
}

#news-feed-widget-content {
    background: inherit;
    overflow-y: scroll;

}

#news-feed-widget-content::-webkit-scrollbar {
    border-radius: 2.3rem;
    width: 5px;
}

#news-feed-widget-content::-webkit-scrollbar-thumb {
    background: var(--color-fourth);
    width: 5px;
    border-radius: 2.3rem;
}

#news-feed-widget-content::-webkit-scrollbar-track {
    background: var(--color-third);
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 2.3rem;
}

#news-feed-widget-header {
    position: sticky;
    top: 0;
    background: inherit;
    text-align: center;
}



#news-feed-widget-header {
    border-bottom: 1px solid var(--color-third);
    z-index: 1;
}

.news-feed-widget-body-post {
    border-bottom: 1px solid var(--color-third);
}

.news-feed-widget-body-post-author {
    color: var(--color-primary);
}

.news-feed-widget-body-post-content {
    color: white;
    text-decoration: none;
    transition: all .2s;
}

.news-feed-widget-body-post-content:hover {
    color: #dddddd;
    text-decoration: none;
}