.address-clicker {
    font-size: .9rem;
    transition: all .2s;
}

.address-clicker:hover {
    cursor: pointer;
    opacity: .8;
}

.idui-popover.address-clicker-popover {
    padding: 0rem;
    z-index: 1100 !important;
}