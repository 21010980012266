#big-board-filters-widget {
    position: relative;
    width: 100%;
    /* height: 300px; */
    background: transparent;
}

#leaderboard-widget-content {
    background: inherit;
    overflow-y: scroll;
}

#alpha-club-post-btn {
    padding-top: .2rem;
    padding-bottom: .2rem;
    min-width: 77px;
}

#alpha-club-post-search-bar {
    background: var(--color-third);
    border-radius: 5px;
    border: 1px solid var(--color-fourth);
}

#alpha-club-post-search-bar #search-button {
    color: var(--color-fourth);
    font-size: .85rem;
    padding: .2rem 0.5rem;
}

#alpha-club-post-search-bar input {
    background: transparent;
    border: none;
    outline: none;
    color: white;
    font-size: .9rem;
    padding: .2rem 0.5rem;
    width: 100%;
}

#alpha-posts-filters-container {
    position: relative;
    width: 100%;
    /* height: 300px; */
    background: var(--color-second);
    border-radius: 2.3rem;
    border: 1px solid var(--color-third);
    overflow: hidden;
}

.accordion-item {
    background-color: transparent;
    color: white;
    border: none;
    border-top: 1px solid var(--color-third) !important;
}

.accordion-item .accordion-header .accordion-button {
    background-color: transparent;
    color: white;
    border: none;
    padding-left: 10%;
    font-size: 1.1rem;
}

.accordion-item .accordion-header .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.accordion-item .accordion-header .accordion-button:focus {
    box-shadow: none !important;
}

#tokens-filter-header {
    /* border-bottom: var(--primary-border); */
}

#tokens-filter-header>button {
    display: flex;
    flex-wrap: wrap;
    box-shadow: none;
}

#tokens-filter-body {
    border-top: var(--primary-border);
}