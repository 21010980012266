#post-writer-modal .modal-content {
    background: var(--color-second);
    color: white;
    border: 1px solid var(--color-third);
    border-radius: 1.5rem;
}

.post-writer-modal-type-selection-btn {
    display: flex;
    flex-direction: column;
    width: 120px;
    height: 120px;
    justify-content: center;
    align-items: center;
    color: white;
    border: var(--primary-border);

    transition: all .3s;
}

.post-writer-modal-type-selection-btn img {
    width: auto;
    height: 60px;
}

.post-writer-modal-type-selection-btn:hover {
    background: var(--color-third);
}


#post-writer-modal-header-type-indicator {
    font-size: .7rem;
    color: rgba(180, 180, 180, 0.9);
    margin-bottom: .3rem;
    margin-left: .2rem;

    transition: all .2s;
}

#post-writer-modal-header-type-indicator:hover {
    color: white;
    text-decoration: underline;
}



#post-writer-modal-trade-details-position .price-range-info.uptrend {
    background: var(--selection-color);
}

#post-writer-modal-trade-details-position .price-range-info.downtrend {
    background: var(--error-color);
}

#post-writer-modal-title-input {
    font-size: 1.5rem;
}

.post-writer-input,
.post-writer-btn,
.select-token-dropdown button {
    background: transparent;
    color: white;
    border: 1px solid var(--color-third);
    border-radius: .5rem;
    padding-left: .4rem;
    transition: all .3s;
}

#post-writer-modal-trade-details-position .long-btn:hover {
    background: var(--selection-color) !important;
}

#post-writer-modal-trade-details-position .short-btn:hover {
    background: var(--error-color) !important;
}

#post-writer-modal-trade-details .post-writer-input {
    width: 120px;
    min-height: 35px;
}

.post-writer-input-error,
button.post-writer-btn-error {
    border-color: var(--error-color);
}
.post-writer-input-error-text {
    color: var(--error-color);
    min-height: 20px;
    font-size: .8rem;
}

#post-writer-modal-trade-details-timeframe .date-time-input {
    width: 180px;
    padding: .2rem .5rem;
    font-size: 1rem;
}


#post-writer-modal-trade-research {}

#post-writer-modal-research>div:first-child {
    min-height: 200px;
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem;
    border: 1px solid var(--color-third);
    border-bottom: none;
    font-size: 1.1rem;
}

#post-writer-modal-research-toolbar {
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    border: 1px solid var(--color-third);
    background: var(--color-first);
}


#chart-container {
    overflow: hidden;
    border-radius: 0;
    border-top: none;
    border-bottom: none;
}

#chart-container>div {
    margin-top: -586px;
    transition: all 1s;
}

#chart-container>div:not(.active-chart) .chart-link-container {
    margin-top: 508px !important;
}

#advanced-options-container>div.expanded {
    margin-top: .8rem;
}

#chart-container>div.expanded {
    margin-top: 0;
}

.idui-popover.chart-image-input-question-popover {
    display: flex;
    flex-flow: wrap;
    width: 300px !important;
    z-index: 1100 !important;
}

.idui-popover.chart-image-input-question-popover>div {
    width: 100%;
    min-width: unset !important;
    text-align: center;
}


#post-writer-modal-post-btn:disabled {
    opacity: .8;
    cursor: default;
}
