#big-board-nav {
    position: sticky;
    top: 0;
    width: 100%;
    background: var(--color-second);
    z-index: 1000;
}

#nav-logo {
    width: 45px;
    height: auto;
}