.btn-primary {
    background: white !important;
    color: black !important;
    border: none !important;
}

.btn-secondary {
    background: black !important;
    color: white !important;
    border: none !important;
}

.btn-close:focus {
    box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 5%) !important;
}

.spinner-border:not(.spinner-default) {
    color: white;
    width: 50px !important;
    height: 50px !important;
}


.form-select.dark-select {
    background-color: var(--color-first);
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    color: white;
    border: none !important;
    box-shadow: none !important;
}


.idui-popover {
    padding: .3rem .8rem !important;
    font-size: .9rem !important;
}

#share-post-popover .popover-arrow {
    display: none;
}

.modal {
    overflow-y: scroll !important;
}


.ql-toolbar.ql-snow .ql-fill,
.ql-toolbar.ql-snow .ql-stroke.ql-fill {
    fill: white;
}

.ql-toolbar.ql-snow .ql-stroke {
    stroke: white;
}

.ql-toolbar .ql-formats {
    display: flex !important;
    align-items: center;
}


.react-datepicker__day--keyboard-selected {
    background-color: transparent !important;
}

@media (min-width: 1200px) {
    .modal-xl {
        --bs-modal-width: 960px !important;
    }
}

@media (min-width: 992px) {
    .modal-xl {
        --bs-modal-width: 900px !important;
    }
}