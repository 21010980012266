#alpha-post-modal .modal-content {
    background: var(--color-second);
    color: white;
}

#alpha-post-token-pool-stats {
    background: var(--color-first);
    border-radius: .8rem;
    border: 1px solid rgba(170, 170, 170, 0.6);
}

#alpha-post-token-pool-stats .pool-stat-header {
    color: var(--color-secondary);
}

#alpha-post-research p {
    margin-bottom: 0;
}


#delete-post-button {
    color: #999999;
    transition: all .2s;
}

#delete-post-button:enabled:hover {
    color: white;
}

#delete-post-button:disabled {
    color: #333333;
}

.alpha-post-ta-chart img {
    width: 100%;
    height: auto;
}

#alpha-post-modal .modal-body {
    z-index: 0;
}

.alpha-post-details {
    flex-flow: wrap;
}

.alpha-post-research {
    overflow-wrap: anywhere;
}

#nfa-disclaimer-container {
    background: var(--color-first);
    padding: 1rem;
    border-radius: 1rem;
}

#nfa-disclaimer-container p {
    font-size: .75rem;
    margin-bottom: 0rem;
}

.alpha-post-details .price-range-info.uptrend {
    color: var(--selection-color);
}

.alpha-post-details .price-range-info.downtrend {
    color: var(--error-color);
}