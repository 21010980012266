.alpha-post-comment-ta-chart img {
    width: 85%;
    height: auto;
}

.comment-interactions-btn {
    color: #aaaaaa;
    transition: all .2s;
}

.comment-interactions-btn:hover {
    color: white;
}

.alpha-post-comment,
.comment-reply-container {
    position: relative;
}

.comment-replies-line {
    position: absolute;
    width: 2px;
    background: #3f3f3f;
    height: 100%;
    left: 11.5px;

    z-index: 0;
}

.commenter-user-info {
    z-index: 1;
}