#alpha-post-comment-writer-research>div:first-child {
    height: 200px;
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem;
    border: 1px solid var(--color-third);
    border-bottom: none;
    font-size: 1.1rem;
}

#alpha-post-comment-writer-research-toolbar,
#alpha-post-reply-writer-research-toolbar {
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    border: 1px solid var(--color-third);
    background: var(--color-first);
}


#chart-container {
    overflow: hidden;
    border-radius: 0;
    border-top: none;
    border-bottom: none;
}

#chart-container>div {
    margin-top: -586px;
    transition: all 1s;
}

#chart-container>div:not(.active-chart) .chart-link-container {
    margin-top: 508px !important;
}

#chart-container>div.expanded {
    margin-top: 0;
}

.idui-popover.chart-image-input-question-popover {
    display: flex;
    flex-flow: wrap;
    width: 300px !important;
    z-index: 1100 !important;
}

.idui-popover.chart-image-input-question-popover>div {
    width: 100%;
    min-width: unset !important;
    text-align: center;
}


#post-writer-modal-post-btn:disabled {
    opacity: .8;
    cursor: default;
}